import { connect } from 'react-redux';
import LandingPageComponent from './LandingPageComponent';
import { loginActions, loginSelectors } from '../login';
import { tokenSelectors } from '../token';

const mapStateToProps = state => ({
  token: tokenSelectors.getToken(state),
  status: loginSelectors.getLoginStatus(state),
  isLoading: loginSelectors.getIsLoading(state),
});

const mapDispatchToProps = {
  logout: loginActions.logout,
  login: loginActions.login,
  register: loginActions.register
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageComponent);
