import React from 'react';
import styled from 'styled-components';
import { Title, Text, StyleGuide } from '../Theme';
import { useMediaQuery } from '../hooks';
import ThemeProfiles from '../Theme/ThemeProfiles';
import { useTranslation, i18n } from '../i18n';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 0px;
  margin: 50px 10%;
  @media only screen and (max-width: 1024px) {
    margin: 30px 10%;
  }
  @media only screen and (max-width: 414px) {
    margin: 20px 5%;
  }
`;

const IFrameContent = styled.iframe`
  height: 500px;
  border-width: 0px;
  width: 100%;
  padding:30px;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
  }
  @media only screen and (max-width: 414px) {
    height: 330px;
    width:95%;
    margin:10px;
    padding: 0px;
  }
`;

const BlogText = styled(Text)`
color: ${ThemeProfiles.themeLight.fontOnPrimary};
font-size: 1em;
line-height: 24px;
@media (prefers-color-scheme: dark) {
  color: ${ThemeProfiles.themeDark.fontOnPrimary};
}
`;

const CenteredText = styled.div`
display:flex;
justify-content:center;
align-items:center;
width: 300px;
/* @media only screen and (max-width: 414px) {
    width: 80%;
  } */
`;

const langPartLink = () => {
  switch (navigator?.language?.slice(0, 2)) {
    case 'de':
      return 'frame';
    case 'da':
      return 'frame-da';
    default:
      return 'frame-en';
  }
};

/**
 * Container for Blog, integrating iFrame
 */
const BlogComponent = () => {
  const isSmall = useMediaQuery(['(max-width: 414px)']);
  const { t } = useTranslation('common');

  if (i18n.language !== 'de')
    return null;

  return (
    <Layout>
      <Title
        style={{ color: StyleGuide.colors.primary, fontWeight: 'bold', fontSize: '1.7em' }}
      >
        {t('blog')}
      </Title>

      {isSmall ? (
        <CenteredText>
          <BlogText>
            {t('blogText')}
          </BlogText>
        </CenteredText>
      ) : (
        <BlogText>
          {t('blogTextPartOne')}
          <br />
          {t('blogTextPartTwo')}
        </BlogText>
        )}
      <IFrameContent src="https://blog.icatched.de/frame/" scrolling="no" />
      {/* <IFrameContent src={`https://blog.icatched.de/${langPartLink()}/`} scrolling="no" /> */}
    </Layout>
  );
};

export default BlogComponent;
