import React, { useState, useContext } from 'react';
import Router from 'next/router';
import Link from 'next/link';
import styled, { ThemeContext } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import aes from 'crypto-js/aes';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import uuid from 'uuid/v4';
import { formatISO, isValid, parse } from 'date-fns';
import { PreCheckLogin } from '../services/webAppEndPoint';
import { useTranslation } from '../i18n';
import {
  StyleGuide,
  Text,
  RoundedButton,
  InputTextWithIcon,
  InputRadio,
  RegisterButton,
  Icon,
  CustomAlert,
  ThemedText,
} from '../Theme';
import { useCompareWithPrevious, useMeasure } from '../hooks';
import Tracking from '../tracking';

const Content = styled.div`
  width: 500px;
  margin-bottom: 15px;
  background: ${({ setZ, theme: { backgroundPrimary } }) =>
    setZ ? backgroundPrimary : 'transparent'};
  border-radius: 10px;
  padding: 10px;
  z-index: ${({ setZ }) => (setZ ? 15 : '')};
  @media only screen and (max-width: 414px) {
    /* z-index: 'auto'; */
    z-index: ${({ setZ }) => (setZ ? 15 : 'auto')};
    /* background: transparent; */
    background: ${({ setZ, theme: { backgroundSecondary } }) =>
      setZ ? backgroundSecondary : 'transparent'};
  }
`;
const RadioContent = styled.div`
  display: block;
  flex: 1;
  flex-direction: column;
  padding: ${(props) => props.padding};
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px;
`;

const Title = styled(Text)`
  width: 80%;
  margin: 15px auto;
  font-size: 2em;
  margin-bottom: 15px;
  @media screen and (max-width: 414px) {
    font-size: 1.2em;
    line-height: 1.5em;
  }
`;

const FieldContainer = styled(animated.div)`
  overflow: hidden;
  position: relative;
  padding: 0px 3px;
`;

const InvisibleDiv = styled.div`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const RegisterBtn = styled(RegisterButton)`
  padding: 0px 3px;
  margin: 10px 0px 0px 0px;
`;

const OrContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  margin: 10px 15px;
`;

const Splitter = styled.div`
  height: 1px;
  background: lightgrey;
  width: 35%;
`;

const DateFormat = ['DD.MM.YYYY', 'YYYY.MM.DD', 'MM.DD.YYYY'];

const generateFingerPrintHash = () => {
  const { width, height, pixelDepth } = window.screen;
  const { userAgent } = window.navigator;
  return sha256(width + height + pixelDepth + userAgent.replace(/\D+/, '')).toString(
    CryptoJS.enc.Hex
  );
};

const encode = (message) => {
  return aes.encrypt(message, generateFingerPrintHash());
};

const isValidEmail = (email) => {
  return /^[\w-._]+/.test(email) && /.+@.+/.test(email) && /@[^@]+.\w+$/.test(email);
};

const isValidPassword = (password) => {
  return password.length >= 6;
};

/**
 * Register Component of LandingPage
 */
const Register = ({ status, registerFormVisible, setRegisterFormVisible, register, login }) => {
  const theme = useContext(ThemeContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [gender, setGender] = useState(null);
  const [missingMail, setMissingMail] = useState(false);
  const [nonMatchingPasswords, setNonMatchingPasswords] = useState(false);
  const [missingGender, setMissingGender] = useState(false);
  const [lookingFor, setLookingFor] = useState('');
  const { t } = useTranslation(['login', 'common', 'footer', 'profile']);
  const [modalVisible, setModalVisible] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [inputContainerRef, { height: inputContainerHeight }] = useMeasure();
  // 20 ist für Margin von Unterelementen
  const openScroll = useSpring({ height: registerFormVisible ? inputContainerHeight + 20 : 0 });
  useCompareWithPrevious(status, (prevStatus) => {
    if (status && prevStatus !== status && prevStatus !== undefined) {
      switch (status) {
        case 7024: {
          if (email && password && passwordCheck && gender && lookingFor) {
            Router.push({
              pathname: '/register',
              query: { email, gender, pw: encode(password).toString() },
            });
          }
          break;
        }
        case 7046: {
          setModalVisible(true);
          break;
        }
        default:
          break;
      }
    }
  });

  const handleSubmit = () => {
    Tracking.track('landingRegister');
    if (!registerFormVisible) {
      setRegisterFormVisible(true);
      return;
    }
    if (!email) {
      setMissingMail(true);
    }

    setInvalidEmail(!isValidEmail(email));
    setInvalidPassword(!isValidPassword(password));

    if (password !== passwordCheck) {
      setNonMatchingPasswords(true);
      setPasswordCheck('');
      setPassword('');
    }
    if (!gender) {
      setMissingGender(true);
    }
    if (
      gender &&
      email &&
      password === passwordCheck &&
      isValidPassword(password) &&
      isValidEmail(email)
    ) {
      PreCheckLogin(email.trim())
        .then((userExisting) => {
          if (userExisting) setModalVisible(true);
          else {
            Router.push({
              pathname: '/register',
              query: { email, gender, pw: encode(password).toString() },
            });
          }
        })
        .catch((error) => {
          console.log('err', error);
        });
    }
  };

  return (
    <Content setZ={registerFormVisible}>
      <Title color={theme.main} style={{ fontWeight: 'bolder' }}>
        {t('meetNewPeople')}
      </Title>
      <RadioContainer>
        <RadioContent padding="0px">
          <Text color={theme.main} style={{ fontWeight: 'bolder' }} textAlign="left">
            {t('iAm')}
          </Text>
          <InputRadio
            margin="5px"
            error={missingGender}
            checked={gender === 'male'}
            onChange={() => {
              setGender('male');
              if (!lookingFor) {
                setLookingFor('female');
              }
              setRegisterFormVisible(true);
              setMissingGender(false);
            }}
            text={t('common:male')}
          />

          <InputRadio
            margin="10px 5px"
            checked={gender === 'female'}
            onChange={() => {
              setGender('female');
              if (!lookingFor) {
                setLookingFor('male');
              }
              setRegisterFormVisible(true);
              setMissingGender(false);
            }}
            text={t('common:female')}
            error={missingGender}
          />
        </RadioContent>
        <RadioContent padding="0px">
          <Text color={theme.main} style={{ fontWeight: 'bolder' }} textAlign="left">
            {t('profile:searchFor')}
          </Text>

          <InputRadio
            margin="5px"
            checked={lookingFor === 'female'}
            onChange={() => {
              setLookingFor('female');
              setRegisterFormVisible(true);
            }}
            text={t('aWoman')}
          />

          <InputRadio
            margin="10px 5px"
            value="woman"
            checked={lookingFor === 'male'}
            onChange={() => {
              setLookingFor('male');
              setRegisterFormVisible(true);
            }}
            text={t('aMan')}
          />
        </RadioContent>
      </RadioContainer>
      <FieldContainer style={openScroll}>
        <div ref={inputContainerRef}>
          <InputTextWithIcon
            stretch
            value={email}
            error={missingMail || invalidEmail}
            placeholder={t('common:mail')}
            errorPlaceholder={t('missingMail')}
            onChange={({ target: { value: val } }) => {
              setEmail(val.trim());
              setMissingMail(false);
            }}
            margin="10px 0px"
            iconName="post"
            iconSize={20}
          />

          <InputTextWithIcon
            stretch
            minLength={6}
            type="password"
            value={password}
            error={nonMatchingPasswords || invalidPassword}
            isPassword
            placeholder={t('password')}
            errorPlaceholder={t('missingPassword')}
            onChange={({ target: { value: val } }) => {
              setPassword(val);
              setNonMatchingPasswords(false);
            }}
            iconName="password"
            margin="10px 0px"
          />

          <InputTextWithIcon
            stretch
            minLength={6}
            type="password"
            value={passwordCheck}
            isPassword
            error={nonMatchingPasswords || invalidPassword}
            errorPlaceholder={t('nonMatchingPassword')}
            placeholder={t('confirmPassword')}
            onChange={({ target: { value: val } }) => {
              setPasswordCheck(val);
              setNonMatchingPasswords(false);
            }}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                handleSubmit();
              }
            }}
            margin="10px 0px"
            iconName="password"
          />
          {nonMatchingPasswords && (
              <Text color={theme?.main}>{t('nonMatchingPassword')}</Text>
          )}

          {invalidPassword && (
              <Text color={theme?.main}>{t('invalidPassword')}</Text>
          )}

          {invalidEmail && (
              <Text color={theme?.main}>{t('invalidEmail')}</Text>
          )}
        </div>
      </FieldContainer>
      <RegisterBtn onClick={handleSubmit}>
        <Text color="inherit" fat>
          {t('signInForFree')}
        </Text>
      </RegisterBtn>
      <InvisibleDiv visible={registerFormVisible}>
        <OrContainer>
          <Splitter />
          <Text color={theme.fontOnPrimary}>{t('or')}</Text>
          <Splitter />
        </OrContainer>
        <FacebookLogin
          isMobile={false}
          appId="2242235836014537"
          scope="public_profile,email,user_birthday,user_hometown,user_location,user_gender"
          fields="email,first_name,last_name, name,birthday,gender,location.fields(location),hometown.fields(location),picture.width(1080).height(1920)"
          callback={async ({
            accessToken,
            id,
            email: fbMail,
            gender: fbGender,
            location,
            hometown,
            first_name: name,
            picture,
            birthday,
          }) => {
            let alreadyExist = false;

            // Backend Check ob Mail oder googleId vergeben
            await PreCheckLogin(fbMail?.trim())
              .then((userExisting) => {
                if (userExisting) alreadyExist = true;
              })
              .catch((error) => {
                console.log('err', error);
              });

            await PreCheckLogin(id)
              .then((userExisting) => {
                if (userExisting) alreadyExist = true;
              })
              .catch((error) => {
                console.log('err', error);
              });

            if (alreadyExist) {
              await login({ token: accessToken, userId: id, origin: 'facebook' });
            } else if (
              accessToken &&
              id &&
              fbGender &&
              fbMail &&
              isValid(parse(birthday, 'MM/dd/yyyy', new Date())) &&
              picture &&
              (location || hometown) &&
              name
            ) {
              const llocation = hometown?.location || location?.location;
              register({
                token: accessToken,
                userId: id,
                email: fbMail,
                gender: fbGender === 'female' ? 'female' : 'male',
                birthday: formatISO(new Date(birthday)),
                name,
                location: {
                  latitude: llocation?.latitude,
                  longitude: llocation?.longitude,
                  city: llocation?.city,
                  country: llocation?.country,
                },
                picture: { uri: picture?.data?.url, mimeType: 'image/jpeg', id: uuid() },
                origin: 'facebook',
                previewPath: picture?.data?.url,
                leadPage: Router?.query?.state,
              });
            } else {
              Router.push({
                pathname: '/auth-register',
                query: {
                  token: accessToken,
                  userId: id,
                  email: fbMail,
                  name,
                  origin: 'facebook',
                  gender: fbGender === 'female' ? 'female' : 'male',
                  picture: JSON.stringify({
                    uri: picture?.data?.url,
                    mimeType: 'image/jpeg',
                    id: uuid(),
                  }),
                },
              });
            }
          }}
          render={(renderProps) => (
            <RoundedButton
              backgroundColor={StyleGuide.colors.facebook}
              padding="0px 3px"
              margin="10px 0px"
              onClick={() => {
                Tracking.track('landingFBRegister');
                renderProps.onClick();
              }}
            >
              <Icon name="facebook" color={StyleGuide.colors.fontOnPrimary} size={25} />
              <Text color={StyleGuide.colors.fontOnPrimary} fat>
                {t('fbLogin')}
              </Text>
            </RoundedButton>
          )}
        />
        <GoogleLogin
          clientId="361100569190-5a9eafpeqlg3lj4btbb6ul0k6bvot1na.apps.googleusercontent.com"
          redirectUri="https://icatched.de"
          render={(renderProps) => (
            <RoundedButton
              backgroundColor={StyleGuide.colors.google}
              padding="0px 3px"
              margin="5px 0px"
              onClick={() => {
                renderProps.onClick();
              }}
            >
              <Icon name="google" color={StyleGuide.colors.fontOnPrimary} size={25} />
              <Text color={StyleGuide.colors.fontOnPrimary} fat>
                {t('googleLogin')}
              </Text>
            </RoundedButton>
          )}
          responseType="id_token"
          onSuccess={async (response) => {
            let alreadyExist = false;
            // Daten ziehen
            const { tokenId, googleId, profileObj } = response;
            const { imageUrl, email: gMail, givenName, name } = profileObj;

            // Backend Check ob Mail oder googleId vergeben
            await PreCheckLogin(googleId)
              .then((userExisting) => {
                if (userExisting) alreadyExist = true;
              })
              .catch((error) => {
                console.log('err', error);
              });

            await PreCheckLogin(gMail?.trim())
              .then((userExisting) => {
                if (userExisting) alreadyExist = true;
              })
              .catch((error) => {
                console.log('err', error);
              });

            if (alreadyExist) {
              setModalVisible(true);
            } else {
              Router.push({
                pathname: '/auth-register',
                query: {
                  token: tokenId,
                  userId: googleId,
                  email: gMail,
                  name: givenName ?? name,
                  origin: 'google',
                  picture: JSON.stringify({ uri: imageUrl, mimeType: 'image/jpeg', id: uuid() }),
                },
              });
            }
          }}
          onFailure={(response) => console.log(response, 'GOOGLE FAIL')}
          cookiePolicy="single_host_origin"
        />
        <ThemedText
          style={{ margin: '15px 5px 5px 5px', fontSize: '0.8em', color: theme.fontOnPrimary }}
        >
          {`${t('applying')} `}
          <Link href="/agb">
            <span style={{ cursor: 'pointer', fontWeight: '500' }}>{t('footer:tos')}</span>
          </Link>
          {` ${t('and')} `}
          <Link href="/datenschutz">
            <span style={{ cursor: 'pointer', fontWeight: '500' }}>{t('login:privacyHint')}</span>
          </Link>
          <br />
          <div style={{"font-style": "italic", "font-size":"0.6em", "line-height": "1em", "padding-top": "0.2em"}}>{t('footer:notice')}</div>
        </ThemedText>
      </InvisibleDiv>
      <CustomAlert
        show={modalVisible}
        onAccept={() =>
          Router.push({ pathname: '/login-error', query: { showWithoutError: true } })
        }
        onDecline={() => setModalVisible(false)}
        acceptText={t('toLoginScreen')}
        alertTitle={t('registerFail')}
        alertText={t('cannotRegisterAgain')}
        picName="forbidden"
      />
    </Content>
  );
};
export default Register;
