import React, { Fragment } from 'react';
import styled from 'styled-components';
import LandingPage from '../components/LandingPage';
import Footer from '../components/Footer';
import LandingBoxesList from '../components/LandingBoxes';
import BlogComponent from '../components/Blog';
import ThemeProfiles from '../components/Theme/ThemeProfiles';

const Wrapper = styled.div`
background-color: ${ThemeProfiles.themeLight.backgroundPrimary};
@media (prefers-color-scheme: dark) {
background-color: ${ThemeProfiles.themeDark.backgroundPrimary};
}
`;

const Page = () => (
  <Fragment>
    <LandingPage />
    <Wrapper>
      <LandingBoxesList />
      <BlogComponent />
    </Wrapper>
    <Footer />
  </Fragment>
);

async function getInitialProps() {
  return {
    namespacesRequired: ['common', 'landing', 'login', 'footer', 'values'],
  };
}

Page.getInitialProps = getInitialProps;

export default Page;
