import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import LandingBox from './LandingBox';
import ThemeProfiles from '../Theme/ThemeProfiles';
import landingBoxTexts from './LandingBoxTexts';
import { useDarkMode, useMediaQuery } from '../hooks';
import { useTranslation } from '../i18n';

const Layout = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  margin: 50px 20%;
  min-height: 0px;
  height: auto;
  background-color: ${({theme: {backgroundPrimary}}) => backgroundPrimary};
  @media only screen and (max-width: 1500px) {
    margin: 40px 10%;
  }
  @media only screen and (max-width: 1240px) {
    margin: 30px 5%;
  }
`;

const GridLayout = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;
/**
 * List of BenefitComponents linking to origin benefitPage
 */
const LandingBoxesList = () => {
  const isSmall = useMediaQuery(['(max-width: 414px)']);
  const { t } = useTranslation('benefits');
  const darkModeActive = useDarkMode();

  return (
    <ThemeProvider theme={darkModeActive ? ThemeProfiles.themeDark : ThemeProfiles.themeLight}>
      <Layout>
        <GridLayout>
          {isSmall
            ? landingBoxTexts.slice(0, 3).map((item) => {
              return <LandingBox key={item.title} iconName={item.iconName} title={t(item.title)} text={t(item.text)} />;
            })
            : landingBoxTexts.map((item) => {
              return <LandingBox key={item.title} iconName={item.iconName} title={t(item.title)} text={t(item.text)} />;
            })}
        </GridLayout>
      </Layout>
    </ThemeProvider>
  );
};
export default LandingBoxesList;
