import React, { useEffect, useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { useCookies } from 'react-cookie';
import CookieConsent from 'react-cookie-consent';
import { useRouter } from 'next/router';
import Config from '../config';
import { withTranslation } from '../i18n';
import { useDarkMode } from '../hooks';
import ThemeProfiles from '../Theme/ThemeProfiles';
import { Background, Icon, Text, StyleGuide, DocumentTitle } from '../Theme';
import LandingPageHeader from './LandingPageHeader';
import Register from './Register';
import LoadingScreen from '../Loading/LoadingScreen';

const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url('/static/images/couple.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 35% auto;
  margin: 50px 200px 0px 200px;
  min-height: 600px;

  @media only screen and (max-width: 414px) {
    background-size: contain;
  }

  @media only screen and (max-width: 768px) {
    margin: 30px 30px 0px 30px;
    justify-content: center;
  }

  @media only screen and (max-width: 2400px) {
    background-size: 25% auto;
  }
`;

const CookieText = styled.p`
  text-align: center;
  font-size: 15px;
  color: white;
`;

const CookieLink = styled.a`
  color: white;
  transition: 0.3s;
  :hover {
    opacity: 0.8;
  }
`;

const BoxDivContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  display: flex;
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
  align-items: center;
  justify-content: center;
  padding: 20px;
  ${StyleGuide.boxShadowLow}
`;

const DimBackground = styled(animated.div)`
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 15;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
  }
  @media only screen and (max-width: 768px) {
    height: 110%;
  }
`;
/**
 * Landing Page Component with Login and Register
 */
const LandingPage = ({ t, isLoading, logout, token, ...props }) => {
  const [cookies, , removeCookie] = useCookies(['Token', 'StayLogged', 'fromApp']);
  const darkModeActive = useDarkMode();
  const prevToken = useRef(token);
  const [registerFormVisible, setRegisterFormVisible] = useState(false);
  const countMembers = 1000000;
  const countNew = 20000;

  const router = useRouter();

  useEffect(() => {
    const isFramed = () => {
      if (typeof window === 'undefined') return false;
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };
    if (isFramed()) window.open(window.location.href, '_top');
  }, []);

  useEffect(() => {
    if (token && prevToken.current) {
      if (cookies.StayLogged) {
        router.push('/main');
      } else {
        logout();
        removeCookie('Token', { path: '/' });
      }
    }
    prevToken.current = token;
  }, [logout, token]);

  const dimStyle = useSpring({
    to: {
      background: registerFormVisible ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,0)',
    },
  });
  return (
    <ThemeProvider theme={darkModeActive ? ThemeProfiles.themeDark : ThemeProfiles.themeLight}>
      {registerFormVisible && (
        <DimBackground style={dimStyle} onClick={() => setRegisterFormVisible(false)} />
      )}
      <Background>
        <DocumentTitle text={Config.appName} />

        {isLoading && <LoadingScreen />}
        {!cookies?.fromApp && (
          <CookieConsent
            buttonStyle={{ color: '#000', background: '#fff', borderRadius: '20px' }}
            buttonText={t('landing:accept')}
          >
            <CookieText>
              {t('landing:cookieUse')}
              <CookieLink href={Config.urls.privacy}>{t('moreInfo')}</CookieLink>
            </CookieText>
          </CookieConsent>
        )}
        <LandingPageHeader {...props} token={token} />
        <ContentDiv>
          <Register
            {...props}
            registerFormVisible={registerFormVisible}
            setRegisterFormVisible={setRegisterFormVisible}
          />
        </ContentDiv>
        <BoxDivContainer>
          <Box backgroundColor={StyleGuide.colors.primaryVariantDark}>
            <Icon name="holding-hands" size="40" margin="10px" />
            <div>
              <Text fat>{t('numberOfMembers')}</Text>
              <Text fat size="10px">
                {t('numberMember', { count: countMembers.toLocaleString() })}
              </Text>
            </div>
          </Box>
          <Box backgroundColor={StyleGuide.colors.primary}>
            <div>
              <Text fat>{t('common:female')}</Text>
              <Text fat size="10px">
                42%
              </Text>
            </div>
            <Icon name="genderMulti" size="40" margin="10px" />
            <div>
              <Text fat>{t('common:male')}</Text>
              <Text fat size="10px">
                58%
              </Text>
            </div>
          </Box>
          <Box backgroundColor={StyleGuide.colors.primaryVariantLight}>
            <Icon name="chart" size="40" margin="10px" />
            <div>
              <Text fat>{t('newMembers')}</Text>
              <Text fat size="10px">
                {t('perWeek', { count: countNew.toLocaleString() })}
              </Text>
            </div>
          </Box>
        </BoxDivContainer>
      </Background>
    </ThemeProvider>
  );
};
export default withTranslation(['landing', 'login', 'common'])(LandingPage);
